<template>
  <div>
    <!-- <b-tabs v-model="tabIndex" pills>

        <b-tab
        v-for="(item, index) in buttonList"
          :key="index"
          :title="item.name"
          :active="tabIndex === index"
        />

    </b-tabs> -->
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div>
        <b-button
          v-if="item.show === true"
          @click="tabIndex = index"
          v-for="(item, index) in buttonList"
          :variant="tabIndex === index ? 'primary' : 'outline-primary'"
          :key="index"
          class="mr-1"
          >{{ item.name }}</b-button
        >
      </div>
      <div>
        <b-dropdown variant="link" no-caret dropleft>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-for="(item, index) in buttonList"
            v-if="item.show === false"
            @click="switchChange(item.variable, true)"
            :key="index"
          >
            <feather-icon icon="SettingsIcon" />
            <span class="align-middle ml-50">{{ item.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <transition name="zoom-fade" mode="out-in">
      <component :is="currentComponent" />
    </transition>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import storeModule from "@/store/services/eCommerce/mlm";

import PurchaseBonus from "./components/PurchaseBonus.vue";
import RetailBonus from "./components/RetailBonus.vue";
import InviteBonus from "./components/InviteBonus.vue";
import TeamBonus from "./components/TeamBonus.vue";
import Cycle from "./components/Cycle.vue";
import LeaderBonus from "./components/LeaderBonus.vue";
import CommissionPV from "./components/CommissionPV.vue";
import SponsorBonus from "./components/SponsorBonus.vue";
import MatchingBonus from "./components/MatchingBonus.vue";
import Settings from "./components/Settings.vue";

const STORE_MODULE_NAME = "mlm";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BImg,
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    PurchaseBonus,
    RetailBonus,
    InviteBonus,
    TeamBonus,
    Cycle,
    LeaderBonus,
    CommissionPV,
    SponsorBonus,
    MatchingBonus,
    Settings,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line radix
      tabIndex: parseInt(localStorage.getItem("tabIndex")) || 0,
      buttonList: [],
    };
  },
  computed: {
    currentComponent() {
      return this.buttonList[this.tabIndex]?.component || "Settings";
    },
    mlm() {
      if (
        store.state[STORE_MODULE_NAME] &&
        store.state[STORE_MODULE_NAME].respData !== null
      ) {
        return {
          purchaseBonusShow:
            store.state[STORE_MODULE_NAME].respData.purchaseBonusShow,
          retailBonusShow:
            store.state[STORE_MODULE_NAME].respData.retailBonusShow,
          inviteBonusShow:
            store.state[STORE_MODULE_NAME].respData.inviteBonusShow,
          teamBonusShow: store.state[STORE_MODULE_NAME].respData.teamBonusShow,
          cycleShow: store.state[STORE_MODULE_NAME].respData.cycleShow,
          leaderBonusShow:
            store.state[STORE_MODULE_NAME].respData.leaderBonusShow,
          commissionPVShow:
            store.state[STORE_MODULE_NAME].respData.commissionPVShow,
          sponsorBonusShow:
            store.state[STORE_MODULE_NAME].respData.sponsorBonusShow,
          matchingBonusShow:
            store.state[STORE_MODULE_NAME].respData.matchingBonusShow,
        };
      }
      return {
        purchaseBonusShow: false,
        retailBonusShow: false,
        inviteBonusShow: false,
        teamBonusShow: false,
        cycleShow: false,
        leaderBonusShow: false,
        commissionPVShow: false,
        sponsorBonusShow: false,
        matchingBonusShow: false,
      };
    },
  },
  watch: {
    tabIndex(newVal) {
      localStorage.setItem("tabIndex", newVal);
    },
    mlm(newval) {
      this.checkShow();
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule);
    }
    this.fetchData();
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  },
  methods: {
    switchChange(name, status) {
      const obj = {
        name,
        status,
      }
      console.log(obj)
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    checkShow() {
      const lists = [];
      // if (this.mlm.purchaseBonusShow === true) {
      lists.push({
        name: this.$t("Purchase bonus"),
        component: "PurchaseBonus",
        show: this.mlm.purchaseBonusShow,
        variable: 'purchaseBonusShow',
      });
      // }
      // if (this.mlm.inviteBonusShow === true) {
      lists.push({
        name: this.$t("Invite bonus"),
        component: "InviteBonus",
        show: this.mlm.inviteBonusShow,
        variable: 'inviteBonusShow',

      });
      // }
      // if (this.mlm.teamBonusShow === true) {
      lists.push({
        name: this.$t("Team bonus"),
        component: "TeamBonus",
        show: this.mlm.teamBonusShow,
        variable: 'teamBonusShow',

      });
      // }
      // if (this.mlm.cycleShow === true) {
      lists.push({
        name: this.$t("Cycle 4 week"),
        component: "Cycle",
        show: this.mlm.cycleShow,
        variable: 'cycleShow',

      });
      // }
      // if (this.mlm.leaderBonusShow === true) {
      lists.push({
        name: this.$t("Leader bonus"),
        component: "LeaderBonus",
        show: this.mlm.leaderBonusShow,
        variable: 'leaderBonusShow',

      });
      // }
      // if (this.mlm.commissionPVShow === true) {
      lists.push({
        name: this.$t("Commission PV"),
        component: "CommissionPV",
        show: this.mlm.commissionPVShow,
        variable: 'commissionPVShow',

      });
      // }
      // if (this.mlm.sponsorBonusShow === true) {
      lists.push({
        name: this.$t("Sponsor bonus"),
        component: "SponsorBonus",
        show: this.mlm.sponsorBonusShow,
        variable: 'sponsorBonusShow',

      });
      // }
      // if (this.mlm.matchingBonusShow === true) {
      lists.push({
        name: this.$t("Matching bonus"),
        component: "MatchingBonus",
        show: this.mlm.matchingBonusShow,
        variable: 'matchingBonusShow',

      });
      // }
      lists.push({
        name: this.$t("Settings"),
        component: "Settings",
        show: true,
      });
      this.$nextTick(() => {
        // let index = lists.findIndex((e)=>e.show === true)
        // this.tabIndex = index;
        this.buttonList = lists;
      });
    },
    fetchData() {
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/get`)
        .then(() => {
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(
                error.response?.data?.message || "An error occurred"
              ),
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.v-select-custom {
  display: inline-block;
  width: 50;
  margin: 0 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
